<template>
  <v-container
    class="pa-6 d-flex flex-column justify-center align-center"
    style="min-height: 100vh"
  >
    <template
      v-if="reserveStatusCode === 400"
    >
      <v-icon size="64">
        mdi-calendar-heart
      </v-icon>
      <div class="text-h4 mt-4 font-weight-bold">
        {{ $t('machineNotActiveReservationSystem') }}
      </div>
      <div class="text-body-1 mt-2">
        {{ $t('activeReservationAndCallUs') }}
      </div>
      <v-btn
        class="primary mt-4 lighten-2"
        elevation="0"
        height="48px"
        style="border-radius: 36px"
        width="130px"
        @click="activeDialog = true"
      >
        {{ $t('active') }}
      </v-btn>
    </template>
    <template v-else>
      <div
        class="d-flex justify-center align-center"
      >
        <div class="text-h2">
          <div>{{ $t('machineAlreadyActive') }}</div>
        </div>
      </div>
      <div
        class="d-flex justify-center align-center"
      >
        <div class="text-caption">
          {{ $t('scanQRCodeOrSignIn') }} {{ reserveStatus.hostName }}
        </div>
      </div>
      <div
        class="d-flex justify-center align-center"
      >
        <div class="mt-2">
          <v-img
            :src="QRCode"
            width="128px"
          />
        </div>
      </div>
    </template>
    <v-dialog
      v-model="activeDialog"
      max-width="500px"
      persistent
    >
      <v-card class="pa-4">
        <div class=" d-flex">
          <div class="text-h3 font-weight-bold">
            激活预定系统
          </div>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="activeDialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="usefulPassword">
          <div class="mt-4">
            <div class="text-h4">
              请输入密码:
            </div>
            <div class="text-caption">
              注:预定系统开通密码请咨询客服
            </div>
            <v-form
              ref="passwordForm"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="checkPassword"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                dense
                outlined
                required
                @click:append="showPassword = !showPassword"
              />
            </v-form>
            <div class="text-h4">
              请输入域名:
            </div>
            <div class="text-caption">
              注:预定网址的前缀(只能使用字母或 "-" )
            </div>
            <v-form
              ref="hostForm"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="userHostName"
                :rules="hostRules"
                dense
                outlined
                required
              />
            </v-form>
            <div class="text-h4">
              请输入店面地址链接:
            </div>
            <div class="text-caption">
              注:实际店面的google地图的分享链接
            </div>
            <v-form
              ref="mapForm"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="userMapUrl"
                :rules="mapRules"
                dense
                outlined
                required
              />
            </v-form>
          </div>
          <div class="d-flex">
            <v-spacer/>
            <v-btn
              :disabled="!valid"
              class="primary"
              @click="openReserveSystem"
            >
              确定
            </v-btn>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-center text-h3 mt-4">
            密码错误，请联系客服！
          </div>
          <div class="d-flex">
            <v-spacer/>
            <v-btn
              class="primary"
              @click="reloadDialog"
            >
              确定
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { addUserInfo, ifReserveOpen } from '@/common/Utlis/api'
  import { showSuccessMessage, sureTo } from '@/common/utils'

  export default {
    name: 'OpenReserve',
    data: function () {
      return ({
        userMapUrl: null,
        QRCode: '',
        passwordRules: [v => !!v || this.$t('passwordCantEmpty')],
        hostRules: [v => !!v || this.$t('hostCantEmpty')],
        mapRules: [v => !!v || this.$t('URLCantEmpty')],
        valid: true,
        usefulPassword: true,
        showPassword: false,
        checkPassword: '',
        password: 'admin',
        activeDialog: false,
        userHostName: '',
        reserveStatusCode: null,
        reserveStatus: false,
      })
    },
    mounted () {
      this.reload()
    },
    methods: {
      reloadDialog () {
        this.activeDialog = false
        this.checkPassword = ''
        this.userHostName = ''
        this.usefulPassword = true
      },
      async openReserveSystem () {
        if (this.$refs.passwordForm.validate() && this.$refs.hostForm.validate() && this.$refs.mapForm.validate()) {
          if (this.checkPassword === this.password) {
            await sureTo(
              async () => {
                await addUserInfo({
                  subDominName: this.userHostName.toLowerCase(),
                  mapUrl: this.userMapUrl,
                })
                showSuccessMessage(this.$t('openSuccess'))
                this.activeDialog = false
                await this.$router.push({
                  name: 'OpenTime',
                })
                location.reload()
              })
          } else {
            this.usefulPassword = false
          }
        }
      },
      async reload () {
        const result = JSON.parse((await ifReserveOpen()))
        this.reserveStatus = result.data
        this.reserveStatus = {
          ...this.reserveStatus,
          hostName: this.reserveStatus.userDominName + '.reservation.aaden.io',
        }
        this.reserveStatusCode = result.code
        this.QRCode = 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=' + 'https://' + this.reserveStatus.hostName
      },
    },
  }
</script>

<style scoped>

</style>
